// App.js
import React, { useState } from "react";
import { Route, Routes, Link } from "react-router-dom";
import CreateReward from "./components/CreateReward";
import ClaimReward from "./components/ClaimReward";
import LanguageSelector from "./components/LanguageSelector";
import CreateMultipleRewards from "./components/CreateMultipleRewards"; // Импортируем компонент выбора языка

const App = () => {
    const [language, setLanguage] = useState('en'); // Устанавливаем язык по умолчанию

    return (
        <div>

            <nav className="navbar">
                <Link to="/" className="nav-link">Create Reward</Link>
                <Link to="/claim" className="nav-link">Claim Reward</Link>

            </nav>
            <Routes>
                <Route path="/" element={<CreateMultipleRewards language={language} />} />
                <Route path="/claim" element={<ClaimReward language={language} />} />
            </Routes>
            <LanguageSelector currentLanguage={language} setLanguage={setLanguage} /> {/* Добавляем выбор языка */}
        </div>
    );
};

export default App;

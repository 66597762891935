// LanguageSelector.js
import React from 'react';

const LanguageSelector = ({ currentLanguage, setLanguage }) => {
    const languages = ['en', 'ru', 'uk'];

    return (
        <div className="language-selector">
            <select value={currentLanguage} onChange={(e) => setLanguage(e.target.value)}>
                {languages.map((lang) => (
                    <option key={lang} value={lang}>
                        {lang === 'en' ? 'English' : lang === 'ru' ? 'Русский' : 'Українська'}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default LanguageSelector;

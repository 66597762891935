// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next) // Передаем i18n в react-i18next
    .init({
        resources: {
            en: {
                translation: {
                    "Create Reward": "Create Reward",
                    "Claim Reward": "Claim Reward",
                    "Amount (in ETH)": "Amount (in ETH)",
                    "Secret (unique code)": "Secret (unique code)",
                    "Recipient Address": "Recipient Address",
                    "Reward created successfully! Save this secret code:": "Reward created successfully! Save this secret code:",
                    "Please install MetaMask!": "Please install MetaMask!",
                    "Reward claimed successfully!": "Reward claimed successfully!",
                    "Error creating reward": "Error creating reward",
                    "Error claiming reward": "Error claiming reward",
                    "Save this secret securely as it will be needed to claim the reward!": "Save this secret securely as it will be needed to claim the reward!",
                    "Copy Secret": "Copy Secret",
                }
            },
            ru: {
                translation: {
                    "Create Reward": "Создать награду",
                    "Claim Reward": "Получить награду",
                    "Amount (in ETH)": "Сумма (в ETH)",
                    "Secret (unique code)": "Секретный код (уникальный)",
                    "Recipient Address": "Адрес получателя",
                    "Reward created successfully! Save this secret code:": "Награда успешно создана! Сохраните этот секретный код:",
                    "Please install MetaMask!": "Пожалуйста, установите MetaMask!",
                    "Reward claimed successfully!": "Награда успешно получена!",
                    "Error creating reward": "Ошибка при создании награды",
                    "Error claiming reward": "Ошибка при получении награды",
                    "Save this secret securely as it will be needed to claim the reward!": "Сохраните этот секрет надежно, он понадобится для получения награды!",
                    "Copy Secret": "Скопировать секрет",
                }
            },
            uk: {
                translation: {
                    "Create Reward": "Створити нагороду",
                    "Claim Reward": "Отримати нагороду",
                    "Amount (in ETH)": "Сума (в ETH)",
                    "Secret (unique code)": "Секретний код (унікальний)",
                    "Recipient Address": "Адреса отримувача",
                    "Reward created successfully! Save this secret code:": "Нагороду успішно створено! Збережіть цей секретний код:",
                    "Please install MetaMask!": "Будь ласка, встановіть MetaMask!",
                    "Reward claimed successfully!": "Нагороду успішно отримано!",
                    "Error creating reward": "Помилка при створенні нагороди",
                    "Error claiming reward": "Помилка при отриманні нагороди",
                    "Save this secret securely as it will be needed to claim the reward!": "Збережіть цей секрет надійно, він знадобиться для отримання нагороди!",
                    "Copy Secret": "Скопіювати секрет",
                }
            },
        },
        lng: "en", // Язык по умолчанию
        fallbackLng: "en", // Язык по умолчанию, если перевод отсутствует
        interpolation: {
            escapeValue: false // React уже защищает от XSS
        }
    });

export default i18n;

import React, { useState } from "react";
import { ethers } from "ethers";
import { keccak256, randomBytes, hexlify } from "ethers";
import QRWidget from "./QRWidget"; // Импортируем виджет QR-кода
import RewardContract from "../RewardContract.json";
import '../styles/styles.css';
import { NetworkOptions,ContractAddresses } from "../constants"; // Импортируем константу сетей

export const REWARD_COMMISSION = process.env.REACT_APP_REWARD_COMMISSION_ETH || 10;

const CreateMultipleRewards = ({ language }) => {
    const [amount, setAmount] = useState("");
    const [rewardCount, setRewardCount] = useState(1); // Количество наград
    const [rewards, setRewards] = useState([]); // Массив для хранения данных о наградах
    const [feeAmount, setFeeAmount] = useState(0);
    const [netAmount, setNetAmount] = useState(0);
    const [selectedNetwork, setSelectedNetwork] = useState("ethereum");
    const [dropdownOpen, setDropdownOpen] = useState(false); // Для открытия/закрытия кастомного дропдауна



    const texts = {
        en: {
            secretCode: "Secret Code",
            createReward: "Create Reward",
            amountPlaceholder: "Amount",
            feeAmount: "Fee Amount:",
            netAmount: "Net Amount to Recipient:",
            copy: "Copy",
            copyLink: "Copy Link",
            rewardCheck: "Reward Check",
            selectNetwork: "Select Network:",
            rewardCount: "Number of Rewards",
            noMetamask: "Please install MetaMask!",
            copySuccess: "Link copied to clipboard!",
            copyFail: "Failed to copy link!",
        },
        ru: {
            secretCode: "Код",
            createReward: "Создать награду",
            amountPlaceholder: "Сумма",
            feeAmount: "Сумма комиссии:",
            netAmount: "Чистая сумма для получателя:",
            copy: "Скопировать",
            copyLink: "Скопировать ссылку",
            rewardCheck: "Чек награды",
            selectNetwork: "Выберите сеть:",
            rewardCount: "Количество наград",
            noMetamask: "Пожалуйста, установите MetaMask!",
            copySuccess: "Ссылка скопирована в буфер обмена!",
            copyFail: "Не удалось скопировать ссылку!",
        },
        uk: {
            secretCode: "Код",
            createReward: "Створити винагороду",
            amountPlaceholder: "Сума",
            feeAmount: "Сума комісії:",
            netAmount: "Чиста сума для отримувача:",
            copy: "Скопіювати",
            copyLink: "Скопіювати посилання",
            rewardCheck: "Чек винагороди",
            selectNetwork: "Оберіть мережу:",
            rewardCount: "Кількість нагород",
            noMetamask: "Будь ласка, встановіть MetaMask!",
            copySuccess: "Посилання скопійовано в буфер обміну!",
            copyFail: "Не вдалося скопіювати посилання!",
        },
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleNetworkSelect = (network) => {
        setSelectedNetwork(network.value);
        setDropdownOpen(false);
        switchNetwork(network.value);
    };

    const handleAmountChange = (e) => {
        const value = e.target.value;
        setAmount(value);

        if (!isNaN(value) && value > 0) {
            const parsedAmount = ethers.parseUnits(value, "ether");
            const fee = (parsedAmount * REWARD_COMMISSION) / 100n;
            const net = parsedAmount + fee;

            setFeeAmount(fee);
            setNetAmount(net);
        } else {
            setFeeAmount(0);
            setNetAmount(0);
        }
    };

    const switchNetwork = async (network) => {
        if (window.ethereum) {
            try {
                const chainIdMap = {
                    ethereum: "0x1",
                    // bsc: "0x38",
                    // polygon: "0x89",
                    // avalanche: "0xa86a",
                    // fantom: "0xfa",
                    // harmony: "0x63564c40",
                    // cronos: "0x19",
                    // zksync: "0x144",
                    // polygonZkEvm: "0x44D", // Исправлено на правильный Chain ID (1101 в десятичной)
                    // moonbeam: "0x504",
                    // moonriver: "0x505",
                    // celo: "0xa4ec",
                    // aurora: "0x4e454152",
                    // optimism: "0xA",
                    // base: "0x2105", // Base правильный Chain ID (8453 в десятичной)
                    // arbitrum: "0xA4B1",
                    // linea: "0xe704",
                };


                const chainId = chainIdMap[network];

                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId }],
                });
            } catch (switchError) {
                if (switchError.code === 4902) {

                } else {
                    console.error('Failed to switch network:', switchError);
                }
            }
        } else {
            console.info(texts[language].noMetamask);
        }
    };

    const handleRewardCountChange = (e) => {
        setRewardCount(e.target.value);
    };

    const handleCreateRewards = async () => {
        try {
            if (!window.ethereum) {
                alert(texts[language].noMetamask);
                return;
            }

            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const contractAddress = ContractAddresses[selectedNetwork];
            const contract = new ethers.Contract(contractAddress, RewardContract.abi, signer);

            for (let i = 0; i < rewardCount; i++) {
                const randomSecret = randomBytes(32);
                const secretBytes32 = hexlify(randomSecret);

                const hash = keccak256(ethers.toUtf8Bytes(secretBytes32));

                const tx = await contract.createReward(hash, {
                    value: netAmount,
                });
                await tx.wait();

                const rewardLink = `${window.location.origin}/claim?secret=${secretBytes32}&network=${selectedNetwork}`;

                setRewards(prevRewards => [
                    ...prevRewards,
                    {
                        secret: secretBytes32,
                        link: rewardLink,
                    }
                ]);
            }
        } catch (err) {
            console.error("Error creating rewards:", err);
        }
    };

    const handleCopyLink = async (link) => {
        try {
            await navigator.clipboard.writeText(link);
        } catch (error) {
            console.error("Failed to copy link:", error);
        }
    };
    const selectedNetworkOption = NetworkOptions.find(option => option.value === selectedNetwork);
    return (
        <div className="container">
            <h2>{texts[language].createReward}</h2>
            <div className="network-selector">
                <label>{texts[language].selectNetwork}</label>
                <div className="dropdown" onClick={toggleDropdown}>
                    <div className="dropdown-selected">
                        <img
                            src={NetworkOptions.find(option => option.value === selectedNetwork)?.icon}
                            alt={NetworkOptions.find(option => option.value === selectedNetwork)?.label}
                            className="network-icon"
                        />
                        {NetworkOptions.find(option => option.value === selectedNetwork)?.label}
                    </div>
                    {dropdownOpen && (
                        <ul className="dropdown-list">
                            {NetworkOptions.map((network) => (
                                <li key={network.value} onClick={() => handleNetworkSelect(network)}
                                    className="dropdown-item">
                                    <img src={network.icon} alt={network.label} className="network-icon"/>
                                    {network.label}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
            <input
                type="number"
                placeholder={texts[language].rewardCount}
                value={rewardCount}
                onChange={handleRewardCountChange}
                className="input-field"
            />
            <input
                type="text"
                placeholder={texts[language].amountPlaceholder}
                value={amount}
                onChange={handleAmountChange}
                className="input-field"
            />
            <div>
                <p><strong>{texts[language].feeAmount}</strong> {ethers.formatEther(feeAmount)} {selectedNetworkOption?.symbol}</p>
                <p><strong>{texts[language].netAmount}</strong> {ethers.formatEther(netAmount)} {selectedNetworkOption?.symbol}</p>
            </div>
            <button onClick={handleCreateRewards} className="submit-button">{texts[language].createReward}</button>

            {rewards.length > 0 && rewards.map((reward, index) => (
                <div key={index} className="reward-card">
                    <h3>{texts[language].rewardCheck} {index + 1}</h3>
                    <p><strong>{texts[language].secretCode}:</strong> {reward.secret}</p>
                    <QRWidget link={reward.link} language={language} size={128} />
                    <p>
                        <button onClick={() => handleCopyLink(reward.link)} className="copy-button">{texts[language].copyLink}</button>
                    </p>
                </div>
            ))}
        </div>
    );
};

export default CreateMultipleRewards;

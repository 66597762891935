

export const NetworkOptions = [
    { value: 'ethereum', label: 'Ethereum', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/1/0x0000000000000000000000000000000000000000.png', symbol: 'ETH' },
    // { value: 'bsc', label: 'BNB Chain', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/56/0x0000000000000000000000000000000000000000.png', symbol: 'BNB' },
    // { value: 'polygon', label: 'Polygon', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/137/0x0000000000000000000000000000000000000000.png', symbol: 'MATIC' },
    // { value: 'avalanche', label: 'Avalanche', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/43114/0x0000000000000000000000000000000000000000.png', symbol: 'AVAX' },
    // { value: 'fantom', label: 'Fantom', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/250/0x0000000000000000000000000000000000000000.png', symbol: 'FTM' },
    // { value: 'harmony', label: 'Harmony', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/1666600000/0x0000000000000000000000000000000000000000.png', symbol: 'ONE' },
    // { value: 'cronos', label: 'Cronos', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/25/0x0000000000000000000000000000000000000000.png', symbol: 'CRO' },
    // { value: 'zksync', label: 'zkSync Era', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/324/0x0000000000000000000000000000000000000000.png', symbol: 'ETH' },
    // { value: 'polygonZkEvm', label: 'Polygon zkEVM', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/1101/0x0000000000000000000000000000000000000000.png', symbol: 'ETH' },
    // { value: 'moonbeam', label: 'Moonbeam', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/1284/0x0000000000000000000000000000000000000000.png', symbol: 'GLMR' },
    // { value: 'moonriver', label: 'Moonriver', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/1285/0x0000000000000000000000000000000000000000.png', symbol: 'MOVR' },
    // { value: 'celo', label: 'Celo', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/42220/0x0000000000000000000000000000000000000000.png', symbol: 'CELO' },
    // { value: 'aurora', label: 'Aurora', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/1313161554/0x0000000000000000000000000000000000000000.png', symbol: 'ETH' },
    // { value: 'optimism', label: 'Optimism', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/10/0x0000000000000000000000000000000000000000.png', symbol: 'ETH' },
    // { value: 'base', label: 'Base', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/8453/0x0000000000000000000000000000000000000000.png', symbol: 'ETH' },
    // { value: 'arbitrum', label: 'Arbitrum', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/42161/0x0000000000000000000000000000000000000000.png', symbol: 'ETH' },
    // { value: 'linea', label: 'Linea', icon: 'https://static.cx.metamask.io/api/v1/tokenIcons/59144/0x0000000000000000000000000000000000000000.png', symbol: 'ETH' },
];
// ethereum/frontend/src/constants.js

export const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS || '0x2A06C11C654747E81D98Af043Ca336Ced83337F9';

export const ContractAddresses = {
    ethereum: CONTRACT_ADDRESS,
    // bsc: "0x2A06C11C654747E81D98Af043Ca336Ced83337F9",
    // polygon: "0x2A06C11C654747E81D98Af043Ca336Ced83337F9",
    // avalanche: "0x2A06C11C654747E81D98Af043Ca336Ced83337F9",
    // fantom: "0x2A06C11C654747E81D98Af043Ca336Ced83337F9",
    // harmony: "0x2A06C11C654747E81D98Af043Ca336Ced83337F9",
    // cronos: "0x2A06C11C654747E81D98Af043Ca336Ced83337F9",
    // zksync: "0x2A06C11C654747E81D98Af043Ca336Ced83337F9",
    // polygonZkEvm: "0x2A06C11C654747E81D98Af043Ca336Ced83337F9",
    // moonbeam: "0x2A06C11C654747E81D98Af043Ca336Ced83337F9",
    // moonriver: "0x2A06C11C654747E81D98Af043Ca336Ced83337F9",
    // celo: "0x2A06C11C654747E81D98Af043Ca336Ced83337F9",
    // aurora: "0x2A06C11C654747E81D98Af043Ca336Ced83337F9",
    // optimism: "0x2A06C11C654747E81D98Af043Ca336Ced83337F9",
    // base: "0x2A06C11C654747E81D98Af043Ca336Ced83337F9",
    // arbitrum: "0x2A06C11C654747E81D98Af043Ca336Ced83337F9",
    // linea: "0x2A06C11C654747E81D98Af043Ca336Ced83337F9",
};

import React, { useState, useEffect } from "react";
import Confetti from "react-confetti"; // Импортируем библиотеку для конфетти
import '../styles/styles.css'; // Импортируем стили
import { NetworkOptions } from "../constants"; // Импортируем константу сетей

export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:9000';

const ClaimReward = ({ language }) => {
    const [secret, setSecret] = useState("");
    const [recipient, setRecipient] = useState("");
    const [loading, setLoading] = useState(false); // Состояние для отслеживания загрузки
    const [showConfetti, setShowConfetti] = useState(false); // Состояние для показа конфетти
    const [selectedNetwork, setSelectedNetwork] = useState("ethereum"); // Состояние для выбора сети
    const [dropdownOpen, setDropdownOpen] = useState(false); // Управление состоянием дропдауна

    // Локализация
    const texts = {
        en: {
            title: "Claim Reward",
            secretPlaceholder: "Secret (unique code)",
            recipientPlaceholder: "Recipient Address",
            button: "Claim Reward",
            successMessage: "Reward claimed successfully!",
            errorMessage: "Error claiming reward",
            selectNetwork: "Select Network:",
        },
        ru: {
            title: "Получить награду",
            secretPlaceholder: "Секрет (уникальный код)",
            recipientPlaceholder: "Адрес получателя",
            button: "Получить награду",
            successMessage: "Награда успешно получена!",
            errorMessage: "Ошибка при получении награды",
            selectNetwork: "Выберите сеть:",
        },
        uk: {
            title: "Отримати винагороду",
            secretPlaceholder: "Секрет (унікальний код)",
            recipientPlaceholder: "Адреса отримувача",
            button: "Отримати винагороду",
            successMessage: "Винагороду успішно отримано!",
            errorMessage: "Помилка під час отримання винагороди",
            selectNetwork: "Оберіть мережу:",
        },
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const secretFromUrl = queryParams.get('secret');
        const networkFromUrl = queryParams.get('network');

        if (secretFromUrl) {
            setSecret(secretFromUrl);
        }

        if (networkFromUrl) {
            setSelectedNetwork(networkFromUrl);
        }
    }, []);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen); // Переключение дропдауна
    };

    const handleNetworkSelect = (network) => {
        setSelectedNetwork(network.value); // Сохраняем выбранную сеть
        setDropdownOpen(false); // Закрываем дропдаун
    };

    const handleClaimReward = async () => {
        setLoading(true); // Начало загрузки
        try {
            const response = await fetch(API_URL+'/claim-reward', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ secret, recipient, network: selectedNetwork }),
            });

            if (response.ok) {
                setShowConfetti(true); // Показываем конфетти при успехе
                setTimeout(() => setShowConfetti(false), 5000); // Прячем конфетти через 5 секунд
            } else {
                throw new Error(texts[language].errorMessage);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false); // Окончание загрузки
        }
    };

    return (
        <div className="container">
            {showConfetti && <Confetti />} {/* Компонент конфетти */}
            <h2>{texts[language].title}</h2>

            <div className="network-selector">
                <label>{texts[language].selectNetwork}</label>
                <div className="dropdown" onClick={toggleDropdown}>
                    <div className="dropdown-selected">
                        <img
                            src={NetworkOptions.find(option => option.value === selectedNetwork)?.icon}
                            alt={NetworkOptions.find(option => option.value === selectedNetwork)?.label}
                            className="network-icon"
                        />
                        {NetworkOptions.find(option => option.value === selectedNetwork)?.label}
                    </div>
                    {dropdownOpen && (
                        <ul className="dropdown-list">
                            {NetworkOptions.map((network) => (
                                <li key={network.value} onClick={() => handleNetworkSelect(network)} className="dropdown-item">
                                    <img src={network.icon} alt={network.label} className="network-icon" />
                                    {network.label}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>

            <input
                type="text"
                placeholder={texts[language].secretPlaceholder}
                value={secret}
                onChange={(e) => setSecret(e.target.value)}
                className="input-field"
            />
            <input
                type="text"
                placeholder={texts[language].recipientPlaceholder}
                value={recipient}
                onChange={(e) => setRecipient(e.target.value)}
                className="input-field"
            />

            {loading ? (
                <div className="loader"></div>
            ) : (
                <button onClick={handleClaimReward} className="submit-button">
                    {texts[language].button}
                </button>
            )}
        </div>
    );
};

export default ClaimReward;

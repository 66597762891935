import React from "react";
import { QRCodeSVG } from "qrcode.react"; // Импортируем QRCodeSVG
import '../styles/QRWidget.css'; // Импортируем стили

const QRWidget = ({ link, language }) => {
    // Локализация
    const texts = {
        en: {
            scanMessage: "Scan this QR code to claim your reward!",
            copyMessage: "HTML code copied to clipboard!",
        },
        ru: {
            scanMessage: "Сканируйте этот QR-код, чтобы получить свою награду!",
            copyMessage: "HTML-код скопирован в буфер обмена!",
        },
        uk: {
            scanMessage: "Скануйте цей QR-код, щоб отримати вашу винагороду!",
            copyMessage: "HTML-код скопійовано в буфер обміну!",
        },
    };

    const htmlEmbedCode = `<img src="${link}" alt="QR Code" />`; // HTML-код для встраивания

    const handleCopy = () => {
        navigator.clipboard.writeText(htmlEmbedCode).then(() => {
            // alert(texts[language].copyMessage);
        }).catch(err => {
            console.error("Could not copy text: ", err);
        });
    };

    return (
        <div className="qr-widget">
            <QRCodeSVG value={link} size={128} />
            {/*<p>{texts[language].scanMessage}</p>*/}
            {/*<button onClick={handleCopy}>{texts[language].copyMessage}</button>*/}
        </div>
    );
};

export default QRWidget;
